import { apiV2 } from '@/services'

const visitList = async (perPage) => {
    try {
        return await apiV2.get(`claim-insurance-status/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitCreate = async (data) => {
    try {
        return await apiV2.post('claim-insurance-status', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitUpdate = async (id, data) => {
    try {
        return await apiV2.put(`claim-insurance-status/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitDelete = async (id) => {
    try {
        return await apiV2.delete(`claim-insurance-status/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitPagination = async (perPage, page) => {
    try {
        return await apiV2.get(`claim-insurance-status/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitFilter = async (perPage, data) => {
    try {
        return await apiV2.post(`claim-insurance-status/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitFilterPagination = async (perPage, page, data ) => {
    try {
        return await apiV2.post(`claim-insurance-status/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitListActive = async (perPage) => {
    try {
        return await apiV2.get(`claim-insurance-status/utilities/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitListActiveFilter = async (perPage, data) => {
    try {
        return await apiV2.post(`claim-insurance-status/utilities/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    visitList,
    visitCreate,
    visitUpdate,
    visitDelete,
    visitPagination,
    visitFilter,
    visitFilterPagination,
    visitListActive,
    visitListActiveFilter
}